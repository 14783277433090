import React from 'react'

import { Box, VStack } from '@chakra-ui/react'

import { Icon } from 'components/Icon'

const Feature = ({ icon = null, color = null, children, sx = {} }) => (
	<Box layerStyle="trio" sx={{ a: { color }, ...sx }}>
		{icon && (
			<Box
				sx={{
					gridColumn: { base: 'col-start 1/span 6', lg: '1'},
					my: 3,
					color,
					textAlign: { base: 'left', lg: 'right' },
					fontSize: '6xl',
				}}
			>
				<Icon icon={icon} />
			</Box>
		)}
		<VStack
			alignItems="flex-start"
			gap={4}
			className="main"
			sx={{
				'> :first-of-type': { mt: 0 },
				'h1 strong': { color: 'blue.brand' },
				fontSize: 'xl'
			}}
		>
			{children}
		</VStack>
	</Box>
)

export default Feature
