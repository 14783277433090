import React from 'react'

import { Box } from '@chakra-ui/react'

export const Rule = () => (
	<Box layerStyle="trio" sx={{ overflow: 'hidden' }}>
		<Box
			sx={{
				m: 0,
				mr: -100,
				height: '12px',
				padding: 0,
				borderRadius: 999,

				opacity: 0.5,
				gridColumn: 'col-start 1/span 7',
				background: 'linear-gradient(90deg, rgba(0,132,255,1) 0%, rgba(0,132,255,1) 60%, rgba(255,0,182,1) 70%, rgba(255,182,0,1) 80%, rgba(0,177,17,1) 90%, rgba(0,132,255,1) 100%);',
			}}
		/>
	</Box>
)
