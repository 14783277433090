import React from 'react'

import { Heading, Text } from '@chakra-ui/react'

import Feature from 'components/Feature'
import Link from 'components/Link'
import { Rule } from 'components/Rule'
import Start from 'components/Start'

const Home = () => (
	<>
		<Feature>
			<Heading as="h1" size="h1">
				<strong>Atty</strong> is the easiest shop to start your small business
			</Heading>

			<Start />
		</Feature>

		<Rule />

		<Feature color="yellow.brand" icon={['fad', 'bolt-lightning']}>
			<Heading as="h2" size="h2">Your online store can be up and running in minutes</Heading>
			<Text>Upload a logo, add some products, add your bank details, and you&apos;re ready to go.</Text>
		</Feature>

		<Feature color="blue.brand" icon={['fad', 'coin-vertical']}>
			<Heading as="h2" size="h2">$3 per month</Heading>
			<Text>We charge a small monthly fee to keep your shop online <strong>after a 30-day free trial</strong>.</Text>
			<Text>
				<Link href="/pricing">See pricing details</Link>
			</Text>
		</Feature>

		<Feature color="green.brand" icon={['fad', 'infinity']}>
			<Heading as="h2" size="h2">Unlimited products, collections, and photos</Heading>
			<Text>We&apos;ve built our tech from the ground up to remove arbitrary limits from your product catalogue.</Text>
		</Feature>

		<Feature color="yellow.brand" icon={['fad', 'brain-circuit']}>
			<Heading as="h2" size="h2">Smart management of inventory and orders</Heading>
			<Text>You will need this. 😊</Text>
		</Feature>

		<Feature color="blue.brand" icon={['fad', 'person-drowning']}>
			<Heading as="h2" size="h2">We love Shopify but not everyone needs the kitchen sink</Heading>
			<Text>If you need full control and customisation over every aspect of e-commerce they are the best, but do you really need that?</Text>
			<Text>
				<Link href="/blog/when-to-use-shopify">Read our blog on when to use Shopify</Link>
			</Text>
		</Feature>

		<Feature color="pink.brand" icon={['fad', 'heart']}>
			<Heading as="h2" size="h2">
                Your feedback helps build our platform
			</Heading>
			<Text>Your feedback and suggestions help build a better platform. We want to be low-cost, to the point, and remove barriers for you and your customers.</Text>
			<Text>
				<Link href="/contact">Get in touch</Link>
			</Text>
		</Feature>
	</>
)

export default Home